import getConfig from "../../config/index";

export const AuthenticationAPIService = {
    async register(userData) {
        const config = getConfig();
        try {
            const response = await fetch(`${config.api.base_url}/register/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });

            if (response.ok) {
                return await response;
            } else {
                const data = await response;
                throw new Error(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    },

    async login(credentials) {
        const config = getConfig();
        try {
            const response = await fetch(`${config.api.base_url}/login/`, {
                method: "POST",
                body: JSON.stringify(credentials),
                headers: { "Content-Type": "application/json" },
            });

            if (response.ok) {
                return response;
            } else {
                const errorData = await response;
                throw new Error(errorData.error || 'Login failed');
            }
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    },

    async forgotPassword(username) {
        const config = getConfig();
        try {
            const response = await fetch(`${config.api.base_url}/forgot-password/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username }),
            });

            if (response.ok) {
                const data = await response.json();
                return data;
            } else {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Forgot password failed');
            }
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    },

    async choosePassword(newPassword, confirmNewPassword, uidb64, token) {
        const config = getConfig();

        try {
            return await fetch(`${config.api.base_url}/choose-password/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token,
                    uidb64: uidb64,
                    new_password: newPassword,
                    confirm_new_password: confirmNewPassword
                })
            });
        } catch (error) {
            console.error('Error', 'An unexpected error occurred', 'error');
        }
    },

async updateProfile(suburb, user_role, province, curriculum, organisationName, organisationType, token) {
    const config = getConfig();
    try {
        const response = await fetch(`${config.api.base_url}/user/update-profile/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            body: JSON.stringify({
                suburb,
                user_role,
                province,
                curriculum,
                organisation_name: organisationName,
                organisation_type: organisationType
            }),
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Profile update failed');
        }
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
    },
    /**
     * Validate Authentication Token
     */
    async validateToken() {
        const config = getConfig();
        const token = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).token : null;

        if (!token) {
            return false;
        }

        try {
            const response = await fetch(`${config.api.base_url}/validate-token/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                return data.token_valid; // Return true/false
            } else {
                return false;
            }
        } catch (error) {
            console.error("Error validating token:", error.message);
            return false;
        }
    }

}