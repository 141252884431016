import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {AuthenticationAPIService} from "../../../services/api/AuthenticationAPIService";

const AuthWrapper = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const validateUserToken = async () => {
            const userData = localStorage.getItem("user");
            const token = userData ? JSON.parse(userData).token : null;

            if (!token) {
                setIsAuthenticated(false);
                navigate("/auth/login");
                return;
            }

            // Call validateToken from UserApiService
            const isValid = await AuthenticationAPIService.validateToken(token);

            if (isValid) {
                setIsAuthenticated(true);
            } else {
                localStorage.removeItem("user");
                setIsAuthenticated(false);
                navigate("/auth/login");
            }
        };

        validateUserToken();
    }, [navigate]);

    // Show loading while checking authentication
    if (isAuthenticated === null) {
        return <div>Loading...</div>;
    }

    // Render children only if authenticated
    return isAuthenticated ? children : null;
};

export default AuthWrapper;
